
/* 	--------------------------------------------------
    uSkinned.net

	BASE
    -------------------------------------------------- */


/* 	--------------------------------------------------
    TABLE OF CONTENTS 
    -------------------------------------------------- 

	1.0 LINKS
	2.0 LISTS
	3.0 MISC
	4.0 TYPOGRAPHY
	
*/

/*
	
	BOOTSTRAP BREAKPOINTS
	
	.col-xs-	< 768px		(container width 100%)
	.col-sm-	> 768px		(container width 750px)
	.col-md-	> 992px		(container width 970px)
	.col-lg-	> 1200px	(container width 1170px)
	
*/



/*	--------------------------------------------------
	1.0 LINKS
	-------------------------------------------------- */

a,
.btn, button,
.slick-prev, .slick-next,
.navigation nav.main ul li,
li.has-child ul li span a::before,
.item .info, .item .inner,
::after, ::before {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a, .btn {
  outline: 0 none !important;
}

.btn {
}

html.no-touch footer a:hover, footer a:focus, footer a:active,
nav.main a, nav a, .promo-pods a, .listing a, .link a,
html.no-touch nav.main a:hover, html.no-touch nav a:hover, html.no-touch .promo-pods a:hover, html.no-touch .listing a:hover, html.no-touch .link a:hover,
html.no-touch nav.main a:focus, html.no-touch nav a:focus, html.no-touch .promo-pods a:focus, html.no-touch .listing a:focus, html.no-touch .link a:focus,
html.no-touch nav.main a:active, html.no-touch nav a:active, html.no-touch .promo-pods a:active, html.no-touch .listing a:active, html.no-touch .link a:active {
  text-decoration: none;
}

.btn {
  border-radius: 0;
  position: relative;
  padding: 12px 15px 12px;
  font-size: 14px;
}

.btn-xs {
  font-size: 10px;
  padding: 6px 10px 6px;
}

.btn-sm {
  font-size: 12px;
  padding: 8px 15px 8px;
}

.btn-lg {
  border-width: 3px;
  padding: 15px 20px 15px;
  font-size: 20px;
}

.btn.no-bg,
button.no-bg {
  background: none;
}

a.see_all {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
}

a.close-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  float: right;
  margin: 15px 15px 0 0;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

  a.close-button em {
    display: none;
  }

  a.close-button span {
    display: block;
    width: 40px;
    height: 2px;
    position: absolute;
    top: 0;
    right: 0;
  }

    a.close-button span.top {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    a.close-button span.bottom {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }


.link {
  margin-top: 40px;
}

  .link.text-uppercase a {
    display: inline-block;
    padding: 0 0 3px;
    border-width: 0 0 2px;
    border-style: solid;
  }


#top-link-block {
  z-index: 200;
  position: relative;
}

  #top-link-block a {
    display: block;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
  }

@media (max-width:991px) {

  #top-link-block a {
    text-align: center;
    border-radius: 0;
    border: 0 none;
    box-shadow: 0 none;
  }
}

@media (min-width:992px) {

  html.no-touch .btn:hover {
  }

  html.no-touch a.close-button:hover {
    opacity: 0.6;
  }

  #top-link-block {
    position: fixed;
    bottom: -60px;
    right: 0;
    padding: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    opacity: 0;
    -webkit-transition: bottom 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -moz-transition: bottom 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -ms-transition: bottom 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transition: bottom 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }

    #top-link-block.affix.show {
      bottom: 0;
      -webkit-opacity: 1;
      -moz-opacity: 1;
      -ms-opacity: 1;
      opacity: 1;
    }

    #top-link-block a {
      width: 60px;
      height: 60px;
      font-size: 25px;
    }
}


/*	--------------------------------------------------
	2.0 LISTS
	-------------------------------------------------- */

ul, ol {
  padding: 0;
  margin: 0 0 15px;
  line-height: 1.4;
}

ul {
  margin-left: 18px;
  list-style: disc outside;
}

ol {
  margin-left: 16px;
  list-style: decimal;
}

  ul ul,
  ol ol {
    padding: 4px 0 0 8px;
  }

ul ul {
  list-style: circle outside;
}

ol ol {
  list-style: lower-roman;
}

li {
  margin-bottom: 4px;
}

dl {
}

  dl dt {
    font-weight: bold;
    padding-bottom: 2px;
  }

  dl dd {
    padding-bottom: 4px;
  }

ul.nav, ol.nav,
nav ul, nav ol, ul.list, ol.list,
nav ul li, nav ol li, ul.list li, ol.list li {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1;
}



/* 	-------------------------------------------------- 
	3.0 MISC
	-------------------------------------------------- */

.image {
  position: relative;
  overflow: hidden;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

img,
html.no-touch .apc.pod-windows .item .info,
html.no-touch .apc.pod-windows .item .info::before {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.ekko-lightbox img {
  width: auto;
  height: auto !important;
  margin: 0 auto;
}


/* IMAGE TRANSITIONS */
.image,
.image.video,
.banner .image,
.promo-pods .image,
.listing .image,
.gallery .image {
  overflow: hidden;
  padding: 0;
}

html.no-touch .image a:hover img,
html.no-touch .image.video a:hover img,
html.no-touch .banner .item a:hover img,
html.no-touch .promo-pods .item a:hover img,
html.no-touch .listing .item a:hover img,
html.no-touch .gallery .item a:hover img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

html.no-touch .apc.pod-windows .item a.info:hover {
  background-size: 130%;
}

i.fa,
i.before {
  margin-right: 5px;
}

  i.fa.after,
  i.after {
    margin-right: 0;
    margin-left: 5px;
  }

.glyphicon.lg {
  font-size: 40px;
}

@media (max-width:767px) {

  .text-left-xs {
    text-align: left;
  }
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}



/*	--------------------------------------------------
	4.0 TYPOGRAPHY
	-------------------------------------------------- */

/*	--------------------------------------------------
	font-family: 	'Open Sans'
	font-weight:	Light 300, Reg 400, Med 500, Bold 700
	-------------------------------------------------- */

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

input, select, textarea, button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

button, .btn {
  font-weight: 300;
  letter-spacing: 0.025em;
}

h1, h2, h3, h4, h5, h6, .heading, nav.main, table thead th, .nav-tabs {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

.banner .heading {
  font-weight: 300;
}

footer .heading {
  font-weight: 500;
}


h1 {
  font-size: 30px;
}

  h1.heading.main {
    margin-top: 0;
  }

h2 {
  font-size: 25px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5, p.heading {
  font-size: 16px;
}

h6, .sub-heading {
  font-size: 14px;
}

h1, h2, h3 {
  margin: 15px 0;
}

#left-col h1:first-child, #right-col h1:first-child, #wide-col h1:first-child, #mid-col h1:first-child {
  margin-top: 0;
}

h4, h5, h6, p.heading {
  margin: 10px 0 15px;
}

footer .heading {
  font-size: 16px;
}

p {
  margin: 8px 0 16px;
}

p, .text {
  line-height: 1.4;
}

  p.caption {
    margin: 10px 0 0;
    font-size: 90%;
    font-style: italic;
    -webkit-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-opacity: 0.6;
    opacity: 0.6;
  }

.intro {
  font-size: 130%;
}

.banner .intro {
  font-size: 140%;
  font-weight: 300;
}

p#skip {
  display: none;
}

b, strong {
  font-weight: 600;
}

address p {
}

table {
  margin: 5px 0 15px;
}

blockquote {
  position: relative;
  padding: 30px 0 15px;
  margin: 15px 0 10px;
  border: 0 none;
  font-size: 150%;
  font-style: italic;
  font-weight: 400;
}

  blockquote::before,
  blockquote::after {
    content: "\f347";
    display: inline-block;
    font-family: "Ionicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    position: absolute;
  }

  blockquote::before {
    top: -5px;
    left: 0;
    font-size: 30px;
  }

  blockquote::after {
    bottom: 0;
    right: 0;
    font-size: 20px;
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
  }

  blockquote cite {
    font-size: 13px;
  }

.alert .info {
}

  .alert .info :first-child {
    margin-top: 0;
  }

  .alert .info :last-child {
    margin-bottom: 0;
  }

p#skip {
  display: none;
}


@media (min-width:768px) {
}


@media (min-width:992px) {

  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5, p.heading {
    font-size: 20px;
  }

  h6, .sub-heading {
    font-size: 18px;
  }

  .intro {
    font-size: 130%;
  }

  .banner .intro {
    font-size: 150%;
  }
}


@media (min-width:1200px) {

  body,
  input, select, textarea, button {
    font-size: 17px;
  }

  h1 {
    font-size: 45px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 24px;
  }

  h5, p.heading {
    font-size: 20px;
  }

  h6, .sub-heading {
    font-size: 18px;
  }

  .intro {
    font-size: 140%;
  }

  .banner .intro {
    font-size: 160%;
  }
}



/*	--------------------------------------------------
	END
	-------------------------------------------------- */


