// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@primary-color: #2196f3;
@secondary-color: #e91e63;

// custom style for the input group
nz-input-group.nz-input-group-floating-label {
  margin-top: 15px;
  margin-bottom: 10px;
  label {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 5px;
    padding: 0px 5px;
    background: white;
    height: 1px;
    display: flex;
    align-items: center;
    color: #555;
  }
}

nz-input-group.legacy-input-styling {
  position: relative;
  margin: 10px 0px;
  padding: 0px 10px;

  label {
    position: absolute;
    z-index: 10;
    padding: 0px 5px 0px 0px;
    top: -2px;
    left: 20px;
    background: white;
    height: 4px;
    display: flex;
    align-items: center;
    color: #888;
  }
}
