
/* 	--------------------------------------------------
    uSkinned.net

	PALETTE
    -------------------------------------------------- */


/* 	--------------------------------------------------
	TABLE OF CONTENTS
    -------------------------------------------------- 

	1.0 BACKGROUNDS
	2.0 TEXT
	3.0 BORDERS
	4.0 BUTTONS
	
	
	COLOURS 
	
	c1	#2196F3		0,169,248
	c2	#073776		7,55,118
	c3	#2b2b2b		43,43,43
	c4	#f7f7f7		247,247,247
	c5 	#ffffff		255,255,255
	c6  #e1e1e1		225,225,225
	
	
*/



/*	--------------------------------------------------
	1.0 BACKGROUNDS
	-------------------------------------------------- */

.c1-bg,
body,
#top-link-block a {
  background-color: #2196F3;
  background-color: #2196F3;
}

  .c1-bg.bg-perc_90 {
    background-color: rgba(33, 150, 243, 0.9);
  }

  .c1-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c1-bg:hover::before {
    background-color: rgba(33, 150, 243, 0.8);
  }

  .c1-bg.bg-perc_70 {
    background-color: rgba(33, 150, 243, 0.7);
  }

  .c1-bg.bg-perc_60 {
    background-color: rgba(33, 150, 243, 0.6);
  }

  .c1-bg.bg-perc_50,
  .apc.banner .item.overlay.c1-bg::before,
  .apc.pod-windows .item.overlay .info.c1-bg::before {
    background-color: rgba(33, 150, 243, 0.5);
  }

  .c1-bg.bg-perc_40 {
    background-color: rgba(33, 150, 243, 0.4);
  }

  .c1-bg.bg-perc_30 {
    background-color: rgba(33, 150, 243, 0.3);
  }

  .c1-bg.bg-perc_20 {
    background-color: rgba(33, 150, 243, 0.2);
  }

  .c1-bg.bg-perc_10 {
    background-color: rgba(33, 150, 243, 0.1);
  }

.c2-bg {
  background-color: #073776;
  background-color: rgba(7,55,118,1);
}

  .c2-bg.bg-perc_90 {
    background-color: rgba(7,55,118,0.9);
  }

  .c2-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c2-bg:hover::before {
    background-color: rgba(7,55,118,0.8);
  }

  .c2-bg.bg-perc_70 {
    background-color: rgba(7,55,118,0.7);
  }

  .c2-bg.bg-perc_60 {
    background-color: rgba(7,55,118,0.6);
  }

  .c2-bg.bg-perc_50,
  .apc.banner .item.overlay.c2-bg::before,
  .apc.pod-windows .item.overlay .info.c2-bg::before {
    background-color: rgba(7,55,118,0.5);
  }

  .c2-bg.bg-perc_40 {
    background-color: rgba(7,55,118,0.4);
  }

  .c2-bg.bg-perc_30 {
    background-color: rgba(7,55,118,0.3);
  }

  .c2-bg.bg-perc_20 {
    background-color: rgba(7,55,118,0.2);
  }

  .c2-bg.bg-perc_10 {
    background-color: rgba(7,55,118,0.1);
  }

.c3-bg,
.modal-backdrop, .modal-backdrop.in,
footer {
  background-color: #2b2b2b;
  background-color: rgba(43,43,43,1);
}

  .c3-bg.bg-perc_90 {
    background-color: rgba(43,43,43,0.9);
  }

  .c3-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c3-bg:hover::before {
    background-color: rgba(43,43,43,0.8);
  }

  .c3-bg.bg-perc_70 {
    background-color: rgba(43,43,43,0.7);
  }

  .c3-bg.bg-perc_60 {
    background-color: rgba(43,43,43,0.6);
  }

  .c3-bg.bg-perc_50,
  .apc.banner .item.overlay.c3-bg::before,
  .apc.pod-windows .item.overlay .info.c3-bg::before {
    background-color: rgba(43,43,43,0.5);
  }

  .c3-bg.bg-perc_40 {
    background-color: rgba(43,43,43,0.4);
  }

  .c3-bg.bg-perc_30 {
    background-color: rgba(43,43,43,0.3);
  }

  .c3-bg.bg-perc_20 {
    background-color: rgba(43,43,43,0.2);
  }

  .c3-bg.bg-perc_10 {
    background-color: rgba(43,43,43,0.1);
  }

.c4-bg {
  background-color: #f7f7f7;
  background-color: rgba(247,247,247,1);
}

  .c4-bg.bg-perc_90 {
    background-color: rgba(247,247,247,0.9);
  }

  .c4-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c4-bg:hover::before {
    background-color: rgba(247,247,247,0.8);
  }

  .c4-bg.bg-perc_70 {
    background-color: rgba(247,247,247,0.7);
  }

  .c4-bg.bg-perc_60 {
    background-color: rgba(247,247,247,0.6);
  }

  .c4-bg.bg-perc_50,
  .apc.banner .item.overlay.c4-bg::before,
  .apc.pod-windows .item.overlay .info.c4-bg::before {
    background-color: rgba(247,247,247,0.5);
  }

  .c4-bg.bg-perc_40 {
    background-color: rgba(247,247,247,0.4);
  }

  .c4-bg.bg-perc_30 {
    background-color: rgba(247,247,247,0.3);
  }

  .c4-bg.bg-perc_20 {
    background-color: rgba(247,247,247,0.2);
  }

  .c4-bg.bg-perc_10 {
    background-color: rgba(247,247,247,0.1);
  }

.c5-bg, #site, .cookie-notice, .spc.pricing-menu {
  background-color: #ffffff;
  background-color: rgba(255,255,255,1);
}

  .c5-bg.bg-perc_90 {
    background-color: rgba(255,255,255,0.9);
  }

  .c5-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c5-bg:hover::before {
    background-color: rgba(255,255,255,0.8);
  }

  .c5-bg.bg-perc_70 {
    background-color: rgba(255,255,255,0.7);
  }

  .c5-bg.bg-perc_60 {
    background-color: rgba(255,255,255,0.6);
  }

  .c5-bg.bg-perc_50,
  .apc.banner .item.overlay.c5-bg::before,
  .apc.pod-windows .item.overlay .info.c5-bg::before {
    background-color: rgba(255,255,255,0.5);
  }

  .c5-bg.bg-perc_40 {
    background-color: rgba(255,255,255,0.4);
  }

  .c5-bg.bg-perc_30 {
    background-color: rgba(255,255,255,0.3);
  }

  .c5-bg.bg-perc_20 {
    background-color: rgba(255,255,255,0.2);
  }

  .c5-bg.bg-perc_10 {
    background-color: rgba(255,255,255,0.1);
  }

.c6-bg {
  background-color: #e1e1e1;
  background-color: rgba(225,225,225,1);
}

  .c6-bg.bg-perc_90 {
    background-color: rgba(225,225,225,0.9);
  }

  .c6-bg.bg-perc_80,
  html.no-touch .apc.pod-windows .item a.info.c6-bg:hover::before {
    background-color: rgba(225,225,225,0.8);
  }

  .c6-bg.bg-perc_70 {
    background-color: rgba(225,225,225,0.7);
  }

  .c6-bg.bg-perc_60 {
    background-color: rgba(225,225,225,0.6);
  }

  .c6-bg.bg-perc_50,
  .apc.banner .item.overlay.c6-bg::before,
  .apc.pod-windows .item.overlay .info.c6-bg::before {
    background-color: rgba(225,225,225,0.5);
  }

  .c6-bg.bg-perc_40 {
    background-color: rgba(225,225,225,0.4);
  }

  .c6-bg.bg-perc_30 {
    background-color: rgba(225,225,225,0.3);
  }

  .c6-bg.bg-perc_20 {
    background-color: rgba(225,225,225,0.2);
  }

  .c6-bg.bg-perc_10 {
    background-color: rgba(225,225,225,0.1);
  }



/*	--------------------------------------------------
	2.0 TEXT
	-------------------------------------------------- */

body {
  color: #555555;
}

a, a:hover, a:focus, a:active {
  color: #2196F3;
  color: #2196F3;
}

.heading, h1, h2, h3, h4, h5, h6, .tables .head {
  color: #2b2b2b;
  color: rgba(43,43,43,1);
}

.c1-bg .text .heading, .c1-bg .text h1, .c1-bg .text h2, .c1-bg .text h3, .c1-bg .text h4, .c1-bg .text h5, .c1-bg .text h6,
.c3-bg .text .heading, .c3-bg .text h1, .c3-bg .text h2, .c3-bg .text h3, .c3-bg .text h4, .c3-bg .text h5, .c3-bg .text h6 {
  color: inherit;
}

.c1-text,
.c1-text:hover, .c1-text:focus, .c1-text:active,
blockquote::before, blockquote::after {
  color: #2196F3;
  color: #2196F3;
}

  .c1-text.text-perc_90 {
    color: rgba(0,169,248,0.9);
  }

  .c1-text.text-perc_80 {
    color: rgba(0,169,248,0.8);
  }

  .c1-text.text-perc_70 {
    color: rgba(0,169,248,0.7);
  }

  .c1-text.text-perc_60 {
    color: rgba(0,169,248,0.6);
  }

  .c1-text.text-perc_50 {
    color: rgba(0,169,248,0.5);
  }

  .c1-text.text-perc_40 {
    color: rgba(0,169,248,0.4);
  }

  .c1-text.text-perc_30 {
    color: rgba(0,169,248,0.3);
  }

  .c1-text.text-perc_20 {
    color: rgba(0,169,248,0.2);
  }

  .c1-text.text-perc_10 {
    color: rgba(0,169,248,0.1);
  }

.c2-text,
.c2-text:hover, .c2-text:focus, .c2-text:active {
  color: #073776;
  color: rgba(7,55,118,1);
}

  .c2-text.text-perc_90 {
    color: rgba(7,55,118,0.9);
  }

  .c2-text.text-perc_80 {
    color: rgba(7,55,118,0.8);
  }

  .c2-text.text-perc_70 {
    color: rgba(7,55,118,0.7);
  }

  .c2-text.text-perc_60 {
    color: rgba(7,55,118,0.6);
  }

  .c2-text.text-perc_50 {
    color: rgba(7,55,118,0.5);
  }

  .c2-text.text-perc_40 {
    color: rgba(7,55,118,0.4);
  }

  .c2-text.text-perc_30 {
    color: rgba(7,55,118,0.3);
  }

  .c2-text.text-perc_20 {
    color: rgba(7,55,118,0.2);
  }

  .c2-text.text-perc_10 {
    color: rgba(7,55,118,0.1);
  }

.c3-text,
.c3-text:hover, .c3-text:focus, .c3-text:active {
  color: #2b2b2b;
  color: rgba(43,43,43,1);
}

  .c3-text.text-perc_90 {
    color: rgba(43,43,43,0.9);
  }

  .c3-text.text-perc_80 {
    color: rgba(43,43,43,0.8);
  }

  .c3-text.text-perc_70 {
    color: rgba(43,43,43,0.7);
  }

  .c3-text.text-perc_60 {
    color: rgba(43,43,43,0.6);
  }

  .c3-text.text-perc_50 {
    color: rgba(43,43,43,0.5);
  }

  .c3-text.text-perc_40 {
    color: rgba(43,43,43,0.4);
  }

  .c3-text.text-perc_30 {
    color: rgba(43,43,43,0.3);
  }

  .c3-text.text-perc_20 {
    color: rgba(43,43,43,0.2);
  }

  .c3-text.text-perc_10 {
    color: rgba(43,43,43,0.1);
  }

.c4-text,
.c4-text:hover, .c4-text:focus, .c4-text:active {
  color: #f7f7f7;
  color: rgba(247,247,247,1);
}

  .c4-text.text-perc_90 {
    color: rgba(247,247,247,0.9);
  }

  .c4-text.text-perc_80 {
    color: rgba(247,247,247,0.8);
  }

  .c4-text.text-perc_70 {
    color: rgba(247,247,247,0.7);
  }

  .c4-text.text-perc_60 {
    color: rgba(247,247,247,0.6);
  }

  .c4-text.text-perc_50 {
    color: rgba(247,247,247,0.5);
  }

  .c4-text.text-perc_40 {
    color: rgba(247,247,247,0.4);
  }

  .c4-text.text-perc_30 {
    color: rgba(247,247,247,0.3);
  }

  .c4-text.text-perc_20 {
    color: rgba(247,247,247,0.2);
  }

  .c4-text.text-perc_10 {
    color: rgba(247,247,247,0.1);
  }

.c5-text,
.c5-text:hover, .c5-text:focus, .c5-text:active,
.c1-bg blockquote, .c3-bg blockquote,
.c1-bg blockquote::before, .c1-bg blockquote::after,
#top-link-block a,
footer .heading, footer a {
  color: #ffffff;
  color: rgba(255,255,255,1);
}

  .c5-text.text-perc_90 {
    color: rgba(255,255,255,0.9);
  }

  .c5-text.text-perc_80 {
    color: rgba(255,255,255,0.8);
  }

  .c5-text.text-perc_70 {
    color: rgba(255,255,255,0.7);
  }

  .c5-text.text-perc_60,
  html.no-touch footer a:hover, footer a:focus, footer a:active {
    color: rgba(255,255,255,0.6);
  }

  .c5-text.text-perc_50 {
    color: rgba(255,255,255,0.5);
  }

  .c5-text.text-perc_40 {
    color: rgba(255,255,255,0.4);
  }

  .c5-text.text-perc_30 {
    color: rgba(255,255,255,0.3);
  }

  .c5-text.text-perc_20 {
    color: rgba(255,255,255,0.2);
  }

  .c5-text.text-perc_10 {
    color: rgba(255,255,255,0.1);
  }

.c6-text,
.c6-text:hover, .c6-text:focus, .c6-text:active,
footer {
  color: #e1e1e1;
  color: rgba(225,225,225,1);
}

  .c6-text.text-perc_90 {
    color: rgba(225,225,225,0.9);
  }

  .c6-text.text-perc_80 {
    color: rgba(225,225,225,0.8);
  }

  .c6-text.text-perc_70 {
    color: rgba(225,225,225,0.7);
  }

  .c6-text.text-perc_60 {
    color: rgba(225,225,225,0.6);
  }

  .c6-text.text-perc_50 {
    color: rgba(225,225,225,0.5);
  }

  .c6-text.text-perc_40 {
    color: rgba(225,225,225,0.4);
  }

  .c6-text.text-perc_30 {
    color: rgba(225,225,225,0.3);
  }

  .c6-text.text-perc_20 {
    color: rgba(225,225,225,0.2);
  }

  .c6-text.text-perc_10 {
    color: rgba(225,225,225,0.1);
  }



/*	--------------------------------------------------
	3.0 BORDERS
	-------------------------------------------------- */

.c1-borders {
  border-color: #2196F3;
  border-color: #2196F3;
}

  .c1-borders.borders-perc_90 {
    border-color: rgba(0,169,248,0.9);
  }

  .c1-borders.borders-perc_80 {
    border-color: rgba(0,169,248,0.8);
  }

  .c1-borders.borders-perc_70 {
    border-color: rgba(0,169,248,0.7);
  }

  .c1-borders.borders-perc_60 {
    border-color: rgba(0,169,248,0.6);
  }

  .c1-borders.borders-perc_50 {
    border-color: rgba(0,169,248,0.5);
  }

  .c1-borders.borders-perc_40 {
    border-color: rgba(0,169,248,0.4);
  }

  .c1-borders.borders-perc_30 {
    border-color: rgba(0,169,248,0.3);
  }

  .c1-borders.borders-perc_20 {
    border-color: rgba(0,169,248,0.2);
  }

  .c1-borders.borders-perc_10 {
    border-color: rgba(0,169,248,0.1);
  }

.c2-borders {
  border-color: #073776;
  border-color: rgba(7,55,118,1);
}

  .c2-borders.borders-perc_90 {
    border-color: rgba(7,55,118,0.9);
  }

  .c2-borders.borders-perc_80 {
    border-color: rgba(7,55,118,0.8);
  }

  .c2-borders.borders-perc_70 {
    border-color: rgba(7,55,118,0.7);
  }

  .c2-borders.borders-perc_60 {
    border-color: rgba(7,55,118,0.6);
  }

  .c2-borders.borders-perc_50 {
    border-color: rgba(7,55,118,0.5);
  }

  .c2-borders.borders-perc_40 {
    border-color: rgba(7,55,118,0.4);
  }

  .c2-borders.borders-perc_30 {
    border-color: rgba(7,55,118,0.3);
  }

  .c2-borders.borders-perc_20 {
    border-color: rgba(7,55,118,0.2);
  }

  .c2-borders.borders-perc_10 {
    border-color: rgba(7,55,118,0.1);
  }

.c3-borders {
  border-color: #2b2b2b;
  border-color: rgba(43,43,43,1);
}

  .c3-borders.borders-perc_90 {
    border-color: rgba(43,43,43,0.9);
  }

  .c3-borders.borders-perc_80 {
    border-color: rgba(43,43,43,0.8);
  }

  .c3-borders.borders-perc_70 {
    border-color: rgba(43,43,43,0.7);
  }

  .c3-borders.borders-perc_60 {
    border-color: rgba(43,43,43,0.6);
  }

  .c3-borders.borders-perc_50 {
    border-color: rgba(43,43,43,0.5);
  }

  .c3-borders.borders-perc_40 {
    border-color: rgba(43,43,43,0.4);
  }

  .c3-borders.borders-perc_30 {
    border-color: rgba(43,43,43,0.3);
  }

  .c3-borders.borders-perc_20 {
    border-color: rgba(43,43,43,0.2);
  }

  .c3-borders.borders-perc_10 {
    border-color: rgba(43,43,43,0.1);
  }

.c4-borders {
  border-color: #f7f7f7;
  border-color: rgba(247,247,247,1);
}

  .c4-borders.borders-perc_90 {
    border-color: rgba(247,247,247,0.9);
  }

  .c4-borders.borders-perc_80 {
    border-color: rgba(247,247,247,0.8);
  }

  .c4-borders.borders-perc_70 {
    border-color: rgba(247,247,247,0.7);
  }

  .c4-borders.borders-perc_60 {
    border-color: rgba(247,247,247,0.6);
  }

  .c4-borders.borders-perc_50 {
    border-color: rgba(247,247,247,0.5);
  }

  .c4-borders.borders-perc_40 {
    border-color: rgba(247,247,247,0.4);
  }

  .c4-borders.borders-perc_30 {
    border-color: rgba(247,247,247,0.3);
  }

  .c4-borders.borders-perc_20 {
    border-color: rgba(247,247,247,0.2);
  }

  .c4-borders.borders-perc_10 {
    border-color: rgba(247,247,247,0.1);
  }

.c5-borders {
  border-color: #ffffff;
  border-color: rgba(255,255,255,1);
}

  .c5-borders.borders-perc_90 {
    border-color: rgba(255,255,255,0.9);
  }

  .c5-borders.borders-perc_80 {
    border-color: rgba(255,255,255,0.8);
  }

  .c5-borders.borders-perc_70 {
    border-color: rgba(255,255,255,0.7);
  }

  .c5-borders.borders-perc_60 {
    border-color: rgba(255,255,255,0.6);
  }

  .c5-borders.borders-perc_50 {
    border-color: rgba(255,255,255,0.5);
  }

  .c5-borders.borders-perc_40 {
    border-color: rgba(255,255,255,0.4);
  }

  .c5-borders.borders-perc_30 {
    border-color: rgba(255,255,255,0.3);
  }

  .c5-borders.borders-perc_20 {
    border-color: rgba(255,255,255,0.2);
  }

  .c5-borders.borders-perc_10 {
    border-color: rgba(255,255,255,0.1);
  }

.c6-borders {
  border-color: #e1e1e1;
  border-color: rgba(225,225,225,1);
}

  .c6-borders.borders-perc_90 {
    border-color: rgba(225,225,225,0.9);
  }

  .c6-borders.borders-perc_80 {
    border-color: rgba(225,225,225,0.8);
  }

  .c6-borders.borders-perc_70 {
    border-color: rgba(225,225,225,0.7);
  }

  .c6-borders.borders-perc_60 {
    border-color: rgba(225,225,225,0.6);
  }

  .c6-borders.borders-perc_50 {
    border-color: rgba(225,225,225,0.5);
  }

  .c6-borders.borders-perc_40 {
    border-color: rgba(225,225,225,0.4);
  }

  .c6-borders.borders-perc_30 {
    border-color: rgba(225,225,225,0.3);
  }

  .c6-borders.borders-perc_20 {
    border-color: rgba(225,225,225,0.2);
  }

  .c6-borders.borders-perc_10 {
    border-color: rgba(225,225,225,0.1);
  }



/*	--------------------------------------------------
	4.0 BUTTONS
	-------------------------------------------------- */

html.no-touch .btn.no-bg.c1-borders:hover, .btn.no-bg.c1-borders:active, .btn.no-bg.c1-borders:focus {
  color: rgba(255,255,255,1);
  background-color: #2196F3;
}

html.no-touch .btn.no-bg.c2-borders:hover, .btn.no-bg.c2-borders:active, .btn.no-bg.c2-borders:focus {
  color: rgba(255,255,255,1);
  background-color: rgba(7,55,118,1);
}

html.no-touch .btn.no-bg.c3-borders:hover, .btn.no-bg.c3-borders:active, .btn.no-bg.c3-borders:focus {
  color: rgba(255,255,255,1);
  background-color: rgba(43,43,43,1);
}

html.no-touch .btn.no-bg.c4-borders:hover, .btn.no-bg.c4-borders:active, .btn.no-bg.c4-borders:focus {
  color: rgba(43,43,43,1);
  background-color: rgba(247,247,247,1);
}

html.no-touch .btn.no-bg.c5-borders:hover, .btn.no-bg.c5-borders:active, .btn.no-bg.c5-borders:focus {
  color: rgba(43,43,43,1);
  background-color: rgba(255,255,255,1);
}

html.no-touch .btn.no-bg.c6-borders:hover, .btn.no-bg.c6-borders:active, .btn.no-bg.c6-borders:focus {
  color: rgba(43,43,43,1);
  background-color: rgba(225,225,225,1);
}

html.no-touch .btn.c1-bg:hover, .btn.c1-bg:active, .btn.c1-bg:focus {
  background-color: rgba(0,169,248,0.8);
}

html.no-touch .btn.c2-bg:hover, .btn.c2-bg:active, .btn.c2-bg:focus {
  background-color: rgba(7,55,118,0.8);
}

html.no-touch .btn.c3-bg:hover, .btn.c3-bg:active, .btn.c3-bg:focus {
  background-color: rgba(43,43,43,0.8);
}

html.no-touch .btn.c4-bg:hover, .btn.c4-bg:active, .btn.c4-bg:focus {
  background-color: rgba(247,247,247,0.8);
}

html.no-touch .btn.c5-bg:hover, .btn.c5-bg:active, .btn.c5-bg:focus {
  background-color: rgba(255,255,255,0.8);
}

html.no-touch .btn.c6-bg:hover, .btn.c6-bg:active, .btn.c6-bg:focus {
  background-color: rgba(225,225,225,0.8);
}



/*	--------------------------------------------------
	5.0 LINKS
	-------------------------------------------------- */

.link.text-uppercase .c1-borders:hover, .link.text-uppercase .btn.c1-borders:active, .link.text-uppercase .btn.c1-borders:focus,
.link.text-uppercase .c2-borders:hover, .link.text-uppercase .btn.c2-borders:active, .link.text-uppercase .btn.c2-borders:focus,
.link.text-uppercase .c4-borders:hover, .link.text-uppercase .btn.c4-borders:active, .link.text-uppercase .btn.c4-borders:focus,
.link.text-uppercase .c5-borders:hover, .link.text-uppercase .btn.c5-borders:active, .link.text-uppercase .btn.c5-borders:focus {
  border-color: #2b2b2b;
  border-color: rgba(43,43,43,1);
}

.link.text-uppercase .c3-borders:hover, .link.text-uppercase .btn.c3-borders:active, .link.text-uppercase .btn.c3-borders:focus {
  border-color: #2b2b2b;
  border-color: rgba(43,43,43,1);
}



/*	--------------------------------------------------
	END
	-------------------------------------------------- */


