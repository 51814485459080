.card {
  box-shadow: @card-shadow;
  margin-bottom: @card-columns-margin;
  background-color: @white;

  &:not([class*="border-"]) {
    border: 0;
  }

  .actions {
    margin-right: 20px;

    .actions__item {
      color: #2196F3;
      font-size: 1.2rem;
      padding: 2px 5px;

      &:hover {
        color: #1582d8;
        background: #ededed;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.card-title {
  font-size: 1.25rem;
  font-weight: normal;

  &:last-child {
    margin-bottom: 0;
  }
}

.card-subtitle {
  color: @text-muted;
  font-size: 1rem;
  font-weight: normal;
  margin-top: -1.35rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.card > .card-body {
  padding:12px 20px;
}

.card > .actions,
.card-body .actions {
  position: absolute;
  right: 15px;
  z-index: 2;
  top: 18px;
}

[class*="card-img"] {
  width: 100%;
}


.card-link {
  text-transform: uppercase;
  font-size: 0.98rem;
  color: @headings-color;
  font-weight: 500;
  display: inline-block;
  margin-top: 1rem;

  &:hover {
    color: lighten(@headings-color, 10%);
  }
}


.card-body__title {
  font-size: 1.1rem;
  color: @headings-color;
  margin-bottom: 0.6rem;
  font-weight: normal;
}

.card--inverse {
  &, 
  .card-title,
  .card-link {
    color: @white;
  }
  
  .card-subtitle {
    color: rgba(255,255,255, 0.8);
  }
}
