@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../assets/less/_mixins.less";
@import "../assets/less/_variables.less";
@import "../assets/less/_fonts.less";
@import "../assets/less/_card.less";

html,
body {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.mat-menu-panel {
  min-width: 200px !important;
}

.filter-menu {
  max-height: 400px !important;
}

.mdc-tab {
  font-weight: 400;
}
